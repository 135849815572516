//Variables (devem ser registradas antes do injetor)
$imgFuse : "/app/external/fuse/components";

.rf-pointer{
    cursor: pointer;
}

img.rf-product-image{
    width: 52px;
    height: 52px;
    border: 1px solid rgba(0,0,0,.12);
}
