#transferencia{
     &.page-layout.carded.fullwidth>.center .content-card .content{
        padding: 0!important;
    }
    .header{
        height: auto!important;
        padding-left: 0;
        padding-right: 0;
            max-height: 200px;
    }
}