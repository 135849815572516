#dashboard-project {
    .header {
        height: 200px;
        min-height: 200px;
        max-height: 200px;
        background: url(/assets/images/backgrounds/park.jpg) no-repeat;
        background-size: cover;
        background-position: center;

        .profile-image {
            margin-right: 24px;
        }

        .name {
            font-size: 34px;
            color: #FFFFFF;
        }

        .actions {

            .md-button {
                text-transform: none;
                padding: 0 16px;
                height: 32px;
                line-height: 32px;
                margin: 0 0 0 8px;
            }
        }
    }
    > .center {



        > .content {
            background: none;
            padding: 0;

            > md-tabs {

                > md-tabs-wrapper {
                    background: #FFFFFF;
                    box-shadow: $whiteframe-shadow-1dp;
                    padding: 0 24px;

                    md-tabs-canvas {
                        height: 56px;

                        md-pagination-wrapper {
                            height: 56px;
                        }

                        .md-tab {
                            padding: 16px 24px;
                            text-transform: none;
                        }
                    }
                }

                &:not(.md-dynamic-height) {

                    md-tabs-content-wrapper {
                        top: 56px;
                    }
                }

                > md-tabs-content-wrapper {

                    md-tab-content {
                        padding: 12px;
                    }
                }
            }
        }
    }

    > .sidenav {
        width: 330px;
        min-width: 330px;
        max-width: 330px;
        padding: 0;
        z-index: 51;
        box-shadow: $whiteframe-shadow-8dp;

        &.md-locked-open {
            width: 250px;
            min-width: 250px;
            max-width: 250px;
            z-index: 2;
            box-shadow: none;
            background: material-color('grey', '200');
        }
    }

    #widgets {

    }

    .ofert-image{
        width: 60px;
        height: 60px;
        border: 1px solid rgba(0,0,0,.12);
    }
}