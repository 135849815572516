.cities{
  #search {
    margin: 0 0 0 32px;
    background: none;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    color: #fff;
    border-bottom: 2px solid hsla(0,0%,100%,.3);
    transition: border-color 0.3s ease;
  }
}
