#xsession-list-sessions{
    .ajust-row{
        margin-left: -8px;
        margin-right: -8px;
    }
    .item-list{
        padding: 8px;

        md-card, .md-card{
            margin: 0!important;
        }
    }
    @media screen and (max-width:400px) {
        h2{
            margin-top: 0;
        }
    }
   
}