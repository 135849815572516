.file-uploud-dialog, .file-uploud-drop {

    color: #707070;

    .drop-dashed {
        border: 1px dashed #707070;
        border-radius: 0;
        padding: 7px;
        min-height: 120px;
        width: 100%;
        &.dragover{
            background-color: rgba(206, 206, 206, 0.61);
            border: 3px dashed #999;
        }
    }

    textarea.comentarios {
        width: 100%;
        height: auto;
        border: 1px solid #707070;
        min-height: 120px;
    }
    
    small {
        color: #707070;
        font-size: 12px;
        line-height: 1.66666666666667;
        margin: 5px 0 20px;
    }
    
    .list-itens-uploud {
        padding:0;
        .item-ulpoud {
            .bg-item-image {
                width: 45px;
                height: 45px;
                // background-image: url('#{$imgW3}/w3-uploud/file-image.png');
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;

                img{
                    padding: 0;
                }
            }
            .trunk {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .padding-l8{
            padding-left: 8px;
        }
        .padding-t8{
            padding-top: 8px;
        }
    }
    
    .pd-b-0{
        padding-bottom: 0;
    }
}

.md-dialog-800 {
    width: 100%;
    max-width: 800px;
}

.md-dialog-600 {
    width: 100%;
    max-width: 600px;
}

.file-uploud-dialog{
    .image-picker {
        width: 250px;
        display: block;
        margin: 0 auto;
    }
}
.file-uploud-drop{
    min-height: 200px;
    padding: 16px;
    margin: 16px;
    border: 3px dashed #888;

    .drop-box{
        span{
            text-align: center;
        }
        .margin-r8{
            margin-right: 8px!important;
        }
    }
    .item-ulpoud{
        margin-bottom: 8px;
    }
}