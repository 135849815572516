#relatorios {
    position: relative;

    > .content {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        &.search-ajust{
            top:280px !important;
        }

        > md-sidenav {
            box-shadow: $whiteframe-shadow-1dp;
            padding: 0;
        }

        .main {
            padding: 24px 24px 0 4px;
            position: absolute;
            top: 0;
            left: 24px;
            right: 0;
            bottom: 0;

            .movimentacoes-list {
                position: relative;
                padding-bottom: 0;

                .md-subheader {
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.14);

                    ._md-subheader-inner {
                        padding: 16px;
                    }

                    .movimentacoes-count {
                        padding-left: 4px;
                    }

                    .list-title {
                        margin-right: 4px;
                    }

                    .edit-group-form {

                        .editable-controls {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;

                            .editable-input {
                                border: 1px solid rgba(0, 0, 0, 0.2);
                                padding: 4px 6px;
                                margin-right: 6px;
                                font-size: 13px;
                            }

                            .editable-buttons {

                            }
                        }

                        .edit-group-button {
                            margin: 0;
                        }

                        .md-button {
                            margin: 0;
                            margin: 0;
                            padding: 0;
                            height: 32px;
                            min-height: 32px;
                            line-height: 32px;
                            width: 32px;
                        }
                    }
                }

                .contact-item {
                    position: absolute;
                    right: 0;
                    left: 0;
                    width: 100%;
                    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
                    background: white;
                    transition: transform 200ms ease-out;
                    will-change: transform;

                    &:last-of-type {
                        margin-bottom: 24px;
                    }

                    &.ng-leave {
                        transform: translateY(0);
                        display: none;

                        &.ng-leave-active {
                            transform: translateY(-100%);
                        }
                    }

                    &.ng-hide {
                        display: none;
                    }

                    &.ng-hide-add-active,
                    &.ng-hide-remove-active {
                        display: block;
                        transition-property: all;
                    }
                }
            }

            .no-results {
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
            }
        }
    }

    #add-contact-button {
        position: fixed;
        bottom: 8px;
        right: 8px;
        padding: 0;
    }

    .programar-list{
        .contact-item.disabled p{
            opacity: 0.5;
        }
    }

    .relatorios-sidenav {

        md-list {

            md-list-item {

                &.selected {

                    .md-button {
                        font-weight: 500;
                        color: #000000;
                    }

                }

                &.new-group-form {

                    .editable-text {
                        width: 100%;

                        .editable-controls {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            padding-left: 8px;

                            .editable-input {
                                border: 1px solid rgba(0, 0, 0, 0.2);
                                padding: 4px 2px;
                                margin-bottom: 8px;
                                font-size: 13px;
                                width: 100%;
                            }

                            .editable-buttons {

                                .md-button {
                                    margin: 0;
                                    margin: 0;
                                    padding: 0;
                                    height: 32px;
                                    min-height: 32px;
                                    line-height: 32px;
                                    width: 32px;
                                }

                            }
                        }
                    }
                }
            }
        }
    }
    .md-chips {
        padding: 0;
        margin-top: 8px;
        /*        .md-chip-input-container{
                    width: 100%;
                }*/


    }
    .required-chip md-chips-wrap{
        background-color: #ffcdd2!important;
    }

    md-input-container>md-select.select-41{
        height: 41px;
        margin-top: 6px;
    }

}



// RESPONSIVE
@media screen and (min-width: $layout-breakpoint-md) {

    #relatorios {

        > .content {

            .main {
                left: 258px;
            }
        }
    }
}
