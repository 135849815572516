.clearfix {
    clear: both;
    display: block;
}
.pointer {
    cursor: pointer;
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.no-style {
    text-decoration: none!important;
}
.pb-imp-8{
    padding-bottom: 8px!important;
}
.dialog-600{
    width: 100%;
    max-width: 600px;
}
.dialog-800{
    width: 100%;
    max-width: 800px;
}
.pv-0-imp{
    padding-left: 0!important;
    padding-right: 0!important;
}
.pb-0-imp{
    padding-bottom: 0!important;
}
.ml-0-imp{
    margin-left: 0!important;
}
.mt-32-imp{
    margin-top: 32px;
}
.mr-16-imp{
    margin-right: 16px!important;
}

.height-percet{
    height: 100%;
}

.width-percet{
    width: 100%;
}

.two-coluns{
    width: 49%!important;
    float: left!important;
    display: block!important;
}
md-datepicker.pick-200{
    width:200px;

    .md-datepicker-input-container, .md-datepicker-input {
        width:200px!important;
    }

    .md-default-theme.md-hue-1 .md-datepicker-input-container, .md-hue-1 .md-datepicker-input-container,
    .md-default-theme.md-hue-1 .md-datepicker-input, .md-hue-1 .md-datepicker-input    {
        width:200px;

    }
}


.b-display{
    display: block;
}
.search-boxes{
    .search{
        height: 48px;
        min-height: 48px;
        padding: 8px 8px 8px 8px;
        background: #FFFFFF;

        md-select.md-default-theme .md-select-value, md-select .md-select-value{
            border-bottom-width: 0px!important;
            border:none!important;
        }

        md-chips.md-default-theme .md-chips .md-chip-input-container input, md-chips .md-chips .md-chip-input-container input{
            padding: 0!important;
        }
        &.date-filter-box{

            md-icon{
                color: #b7b7b7!important;
                margin-right: 0px;
            }

            .filter {

                md-datepicker {
                    margin-right: 0;
                }
            }
        }
    } 
}


.border-imp{
    border: 1px solid #a9a9a9!important;
    padding: 2px 6px!important;
}


input.ng-invalid,
md-input-container .md-input:invalid,
md-select.ng-invalid,
md-select.ng-invalid select,
textarea.ng-invalid {
    background-color: #FFCDD2!important;
    /*border-color: #F44336!important;*/
}
table.simple.min-tam{
    thead tr th{
        min-width: 60px!important;
    }
}


.form-slim{
    md-input-container{
        height: 50px;
        margin:0;

        .md-input{
            line-height: 20px!important;
            height: 26px!important;
        } 
        &.md-input-has-value label:not(.md-no-float){
            transform: translate3d(0,15px,0) scale(.75);
            -webkit-transform: translate3d(0,15px,0) scale(.75);
        }
    }

    .md-select-value{
        min-height: 23px;
    }
}

.font-size-14-imp{
    font-size: 14px!important;
}

table.simple.simple-slim{
    thead tr th, tbody tr td{
        min-width: 40px;
        padding: 0px 8px;
    }
    tbody tr{
        &:hover{
            background-color: #f5f5f5;
        }
    }
    thead tr th:first-child, tbody tr td:first-child{
        padding-left: 8px;
    }
}

md-button.button-option, .md-button.button-option {
    width: 100%;
    margin: 0;
    padding: 0;
}

/* .selectdemoSelectHeader .demo-header-searchbox {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0;
    padding-top:24px;
}
  .selectdemoSelectHeader .demo-select-header {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
    padding-left: 10.667px;
    height: 48px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: auto; }
  .selectdemoSelectHeader md-content._md {
    max-height: 240px; }*/