.rf-activities {
    .activity {
        margin-bottom: 12px;
        .activity-member-avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 16px;
        }
        .activity-member-name {
            font-size: 14px;
            font-weight: 500;
            margin-right: 8px;
        }
        .activity-message {
            font-size: 14px;
            margin-right: 8px;
        }
        .activity-time {
            font-size: 12px;
        }
    }
}
