
md-autocomplete.rf-input-city[md-floating-label] md-input-container{
    margin-bottom: 0;
    padding-bottom: 0;
}
.city-search{
    md-autocomplete.rf-input-city[md-floating-label] md-input-container{
        margin-top: 0!important;
        padding-top: 0!important;
        position: relative!important;
        top: 7px!important;
    }
    md-autocomplete[md-floating-label] md-autocomplete-wrap{
        height: 45px;
    }
    
    input.ng-invalid, md-input-container .md-input:invalid{
        background-color: transparent!important;
    }
    md-input-container.md-default-theme .md-input, md-input-container .md-input{
        border-color: transparent!important;
    }
    md-input-container.md-input-focused label:not(.md-no-float){
        transform: translate3d(0,0,0)!important;
    }
}

