.rf-comment {
  margin-bottom: 16px;
  .comment-member-avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 16px;
  }
  .comment-member-name {
    font-size: 14px;
    font-weight: 500;
  }
  .comment-time {
    font-size: 12px;
  }
  .comment-bubble {
    position: relative;
    padding: 8px;
    background-color: white;
    border: 1px solid rgb(220, 223, 225);
    font-size: 14px;
    margin: 4px 0;
    &:after,
    &:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    }
    &:after {
      left: -7px;
      right: auto;
      top: 0;
      bottom: auto;
      border: 11px solid;
      border-color: white transparent transparent transparent;
    }
    &:before {
      left: -9px;
      right: auto;
      top: -1px;
      bottom: auto;
      border: 8px solid;
      border-color: rgb(220, 223, 225) transparent transparent transparent;
    }
  }
  &.new-comment {
    md-input-container {
      margin: 0;
      border: none;
      textarea {
        border: 1px solid rgb(220, 223, 225);
        background-color: white;
        padding: 8px;
        min-height: 96px;
      }
    }
  }
  .slct-row{
    md-input-container{
      margin-bottom: 0;
      margin-top: -25px;
    }
    .send{
      margin-top: -25px;
    }
  }


    .section-header {
      font-size: 16px;
      i {
        margin-right: 8px;
        color: rgba(0, 0, 0, 0.6);
      }
      .section-title {
        font-weight: 500;
      }
    }


}
