// w3-input-city {
//   li {
//     border-bottom: 1px solid #ccc;
//     height: auto;
//     padding-top: 8px;
//     padding-bottom: 8px;
//     white-space: normal;
//   }
//   li:last-child {
//     border-bottom-width: 0;
//   }
//   .item-title,
//   .item-metadata {
//     display: block;
//     line-height: 2;
//   }
//   .item-title md-icon {
//     height: 18px;
//     width: 18px;
//   }
// }

md-autocomplete.w3-input-city[md-floating-label] md-input-container{
    margin-bottom: 0;
    padding-bottom: 0;
}
